import React from 'react';
import Logo from '../assets/images/logo-white.svg';

const Contact = () => (
	<footer className='contact-me'>
		<div className='contact-me__top'>
			<div className='contact-me__background-image' />
			<div className='contact-me__text-box'>
				<h1 className='contact-me__heading-primary'>
					<span className='contact-me__heading-primary--main'>
						Let’s make something awesome together.
					</span>
					<span className='contact-me__heading-primary--sub'>
						Please send me a message or write me an email, I will
						get back to you as soon as possile.
					</span>
				</h1>
			</div>
		</div>
		<div className='bottom'>
			<form method='post' action='https://formspree.io/xwkrbqwk'>
				<div className='row'>
					<div className='col-6 col-12-mobile'>
						<input
							type='text'
							name='name'
							placeholder='Name'
							aria-label='name'
						/>
					</div>
					<div className='col-6 col-12-mobile'>
						<input
							type='email'
							name='_replyto'
							placeholder='Email'
							aria-label='email'
						/>
					</div>
					<div className='col-12'>
						<textarea
							name='message'
							placeholder='Message'
							aria-label='message'
						/>
					</div>
					<div className='col-12'>
						<input
							type='submit'
							value='Send Message'
							aria-label='submit'
							className='send-message'
						/>
					</div>
				</div>
			</form>
			<p className='copyright'>
				&copy;Copyright 2020 by Sancho Saiyin.
				<img src={Logo} alt='logo-white' className='footer__logo' />
			</p>
		</div>
	</footer>
);

export default Contact;
