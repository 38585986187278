import React from 'react';

import {
	ProjectContainerStyles,
	ProjectItemContainer,
	ProjectItem,
	ImageWrapper,
	Overlay,
	ImageLink,
	Image,
} from './ProjectContainer.styles';

const ProjectContainer = ({ projects, setModal }) => (
	<ProjectContainerStyles>
		{projects &&
			projects.map((project, idx) => (
				<ProjectItemContainer
					key={idx}
					onClick={() => setModal(project)}
				>
					<ProjectItem>
						<ImageWrapper>
							<div
								aria-hidden='true'
								style={{
									width: '100%',
									paddingBottom: '56.338%',
									position: 'static !important',
								}}
							></div>
							<img
								aria-hidden='true'
								src={project.image}
								alt=''
								style={{
									position: 'absolute',
									top: '0px',
									left: '0px',
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									objectPosition: 'center center',
									opacity: 1,
								}}
							/>
						</ImageWrapper>
						<ImageLink aria-label='View detail page of this project!'>
							<Image alt='' src={project.image} />
							<Overlay color={project.color} />
							<div className='image-overlay-container'>
								<div className='image-text-title'>
									{project.title}
								</div>
								<div className='image-text-intro'>
									{project.intro}
								</div>
							</div>
						</ImageLink>
					</ProjectItem>
				</ProjectItemContainer>
			))}
	</ProjectContainerStyles>
);

export default ProjectContainer;
