import styled from 'styled-components';

export const ProjectContainerStyles = styled.div`
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	width: 100%;
`;

export const ProjectItemContainer = styled.div`
	height: 100%;
	position: relative;
	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
`;

export const ProjectItem = styled.div`
	left: 0;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
	&:hover {
		cursor: pointer;
	}
`;

export const ImageWrapper = styled.div`
	height: 100%;
	left: 0;
	position: absolute !important;
	top: 0;
	width: 100%;
	overflow: hidden;
`;

export const Overlay = styled.div`
	${props => `background: ${props.color};`}
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -2;
`;

export const ImageLink = styled.div`
	color: #fff;
	height: 100%;
	left: 0;
	opacity: 0;
	padding: 2rem;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-text-decoration: none;
	text-decoration: none;
	&:hover {
		color: #fff;
		opacity: 1;
		-webkit-text-decoration: none;
		text-decoration: none;
	}
`;

export const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	-webkit-filter: invert(100%);
	filter: invert(100%);
	z-index: -1;
	opacity: 0.08;
	object-fit: cover;
`;
