import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.png';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';
import pic8 from '../assets/images/pic08.jpg';
import pic9 from '../assets/images/pic09.jpg';
import pic10 from '../assets/images/pic10.jpg';
import pic11 from '../assets/images/pic11.jpg';
import pic12 from '../assets/images/pic12.jpg';
import pic13 from '../assets/images/pic13.jpg';
import pic14 from '../assets/images/pic14.png';
import pic15 from '../assets/images/pic15.png';
import pic16 from '../assets/images/pic16.png';

export const projects = [
	{
		id: 1,
		title: 'Virtual Health Precinct',
		intro: 'Look around and learn at VHP',
		description:
			'An educational portal for health education providing an interactive journey through unlockable educational resources. Built with React/Redux, NodeJS, MySQL. Originally Bamboo was used as continuous integration and deployment tool, later switched to GitLab and using it’s CI/CD. The application was packed into Docker components and deployed to Uni’s server for internal use.',
		image: pic1,
		color: '#176cae',
		tags: [
			'React',
			'Redux',
			'NodeJS',
			'Responsive Web Design',
			'MySQL',
			'Pug',
			'Webpack',
			'Docker',
			'Knex',
			'REST',
			'Mocha/Chai',
			'Sass',
		],
	},
	{
		id: 2,
		title: 'Accounting MOOC',
		intro: 'Who is counting?',
		description:
			'A Massive Open Online Course teaching basic accounting principles. Built with React/Redux, MongoDB, using Bamboo/GitLab CI/CD, deployed to the Uni server as Docker components for internal use. For remote teaching purpose, later the application was bundled into a Raspberry Pi. Students will be able to connect to the Raspberry Pi’s Wi-Fi Access Point to engage with the learning resource without internet.',
		image: pic2,
		color: '#f1803b',
		tags: [
			'React',
			'Redux',
			'Raspberry Pi',
			'NodeJS',
			'Reverse Proxy',
			'Nginx',
			'MongoDB',
			'Sass',
		],
	},
	{
		id: 3,
		title: 'FloraXplora',
		intro: 'Explore plants around the campus',
		description:
			'A location based phone application to take students around the university campus and identify different plant species. Build with PHP, MySQL and Google Maps APIs.',
		image: pic3,
		color: '#8E9D31',
		tags: [
			'Php',
			'MySQL',
			'REST',
			'Google Map APIs',
			'jQuery Mobile',
			'Boostrap',
			'Google OAuth',
			'Location-based Game',
		],
	},
	{
		id: 4,
		title: 'Quizzical',
		intro: 'Take the quiz, test your knowledge',
		description:
			'An educational quiz application to send participants short quiz questions at regular intervals. Built with React/Redux, NodeJS, MySQL. Used Bamboo/GitLab CI/CD, deployed to the Uni server as Docker components for internal use.',
		image: pic4,
		color: '#221645',
		tags: [
			'React',
			'Redux',
			'NodeJS',
			'MySQL',
			'Pug',
			'Webpack',
			'Docker',
			'Knex',
			'REST',
			'Mocha/Chai',
			'Sass',
		],
	},
	{
		id: 5,
		title: 'Memory Tools',
		intro: 'Want to improve your memory?',
		description:
			'A number of small game-like web applications to assist users in developing advanced memory techniques. Originally developed as part of a Massive Open Online Course and later developed into a stand-alone website. Built with Angular, NodeJS, MongoDB, jQuery, Bootstrap. Used Bamboo/GitLab CI/CD, deployed to the Uni server as Docker components for internal use. The applications were built as Progressive Web Apps so they can be ‘downloaded’ and used offline.',
		image: pic5,
		color: '#891515',
		tags: [
			'Angular',
			'NodeJS',
			'MongoDB',
			'jQuery',
			'Grunt',
			'Bower',
			'Jasmine',
			'Progressive Web Apps',
			'Docker',
		],
		website: 'https://imps.cdu.edu.au/tools/memory/',
	},
	{
		id: 6,
		title: "Sancho's Clothing",
		intro: 'Fashion and quality, at the best price',
		description:
			"Sancho's Clothing is an eCommerce web application. It was built using React/Redux, NodeJS, and Stripe which allows user to make the payment. It was deployed to the cloud using Heroku.",
		image: pic6,
		color: '#333333',
		tags: [
			'React',
			'React Hooks',
			'Redux',
			'Redux Saga',
			'NodeJS',
			'Google Sign In',
			'Local Storage',
			'Firebase/Firestore',
			'Stripe',
			'Heroku',
			'Styled-Components',
			'Sass',
		],
		website: 'https://sanchos-clothing.herokuapp.com/#/',
	},
	{
		id: 7,
		title: 'Unsplash Images Search',
		intro: 'Find your favorite pictures',
		description:
			'I built this app for learning & practicing purpose, and then I realised that actually it is quite useful too. It made my life easier as I dont always need to open a website with full of distraction - an input textbox plus a grid to display search results can simply do the job.',
		image: pic7,
		color: '#e2a726',
		tags: ['React', 'Redux', 'REST', 'Semantic UI', 'Grid'],
		website: 'https://saincogt.gitlab.io/unsplash-images-search/',
	},
	{
		id: 8,
		title: 'Material Todo List',
		intro: 'To-do the right thing',
		description:
			'When learning Redux, many programmers start with building a To-do app - this is my To-do app developped using React & Redux combined with Material UI.',
		image: pic8,
		color: '#3F51B5',
		tags: ['React', 'Redux', 'Material UI'],
		website: 'https://saincogt.gitlab.io/react-todo-list/',
	},
	{
		id: 9,
		title: "Sancho's Portfolio (previously used)",
		intro: 'An experimental portfolio page for practicing purpose',
		description:
			'An experimental personal portfolio page for purpose of practicing my skill, developed using React. Deployed to GitLab pages using it’s CI/CD. Some of the techniques used: React, SASS, Styled-Components, BEM, Responsive Web Design, Flexbox/Grid, 7-1 Pattern.',
		image: pic9,
		color: '#795548',
		tags: [
			'React',
			'Sass',
			'Styled-Components',
			'BEM',
			'Responsive Design',
			'Flexbox',
			'Grid',
			'7-1 Pattern',
			'FontAwesome',
		],
		website: 'https://saincogt.gitlab.io/sancho-portfolio',
	},
	{
		id: 10,
		title: 'All Colours In a Picture',
		intro: 'Implementation of allRGB code challenge',
		description:
			'This application is my implementation to the code challenge “allRGB”, the objective of allRGB is to create an image with one pixel for every RGB colours; not one colour missing and not one colour twice. I draw an image of Mandelbrot Set, the application was implemented with plain vanilla JavaScript, React, Styled-Components, SASS, Material UI.',
		image: pic10,
		color: '#db5784',
		tags: [
			'React',
			'Jest',
			'Styled-Components',
			'Sass',
			'Material UI',
			'Canvas',
			'Algorithm',
			'Bitwise Operation',
			'Code Challenge',
			'Mandelbrot Set',
		],
		git: 'https://gitlab.com/saincogt/all-colours-in-a-picture',
	},
	{
		id: 11,
		title: 'Crypto Exchange Widget',
		intro: 'Monitor crypto exchange market trends',
		description:
			'A crypto exchange market widget to monitor most recent crypto market prices. Built with React, Express, Semantic UI, Styled-Components. Get updates from Binance.com streams with WebSocket and their RESTful APIs, E2E testing by Jest/Puppeteer.',
		image: pic11,
		color: '#1d5b52',
		tags: [
			'React',
			'WebSocket',
			'Styled-Components',
			'Semantic UI',
			'Jest',
			'Lodash',
			'E2E',
			'Puppeteer',
			'RESTful APIs',
		],
		git: 'https://gitlab.com/saincogt/crypto-exchange',
	},
	{
		id: 12,
		title: "Sancho's Portfolio",
		intro: 'My personal portfolio page',
		description:
			'Bingo, this is the page you are currently browsing. My portfolio page created using React, Gatsby, GraphQL, SASS, Styled-Components, and it is live on Netlify.',
		image: pic12,
		color: '#922820',
		tags: [
			'React',
			'Gatsby',
			'Styled-Components',
			'Sass',
			'Responsive Design',
			'Flexbox',
			'Grid',
			'Netlify',
			'FontAwesome',
		],
		website: 'https://realsancho.me/',
	},
	{
		id: 13,
		title: 'HiDevs',
		intro: 'Share thoughts and ideas, and get help from other developers.',
		description:
			'HiDevs is a social network website for the developers/programmers. User can create a developer profile/portfolio, share thoughts, ideas and get help from other developers. It is my personal full stack project, fully implemented both backend and frontend with MERN stack (MongoDB, Express, React, NodeJS). The application was deployed to cloud server using Heroku and Netlify. Other relevant techniques: REST, JSON Web Tokens, Redux, Axios, Styled-Components, etc.',
		image: pic13,
		color: '#17a2b8',
		tags: [
			'Full Stack',
			'MERN Stack',
			'React',
			'Express',
			'MongoDB',
			'NodeJS',
			'JSON Web Token',
			'Heroku',
			'Netlify',
			'Sass',
			'Styled-Components',
			'Responsive Design',
			'FontAwesome',
		],
		website: 'https://hidevs.herokuapp.com/',
		git: 'https://github.com/saincogt/hidevs/',
	},
	{
		id: 14,
		title: 'Breaking Bad Cast',
		intro: 'A simple react application for my favorite show',
		description:
			'A simple Breaking Bad Cast website using Breaking Bad API https://breakingbadapi.com/. This application is live and hosted on GitLab.',
		image: pic14,
		color: '#0d3e10',
		tags: [
			'React',
			'GitLab CI/CD',
			'GitLab Pages',
			'Styled-Components',
			'Axios',
		],
		website: 'https://saincogt.gitlab.io/breaking-bad/',
		git: 'https://github.com/saincogt/breaking-bad',
	},
	{
		id: 15,
		title: 'React SVG Plot',
		intro:
			'A React web application which can plot SVG shapes by given commands without using any 3rd party plotting libriaries.',
		description:
			"React SVG Plot was developed for a coding challenge which required without using any 3rd party plotting libraries. By entering a series of commands, the application can draw various types of shapes such as polygons, circles, rectangles, lines... etc. The application is hosted on GitLab Pages, deployed using GitLab's CI/CD.",
		image: pic15,
		color: '#333333',
		tags: ['React', 'GitLab CI/CD', 'GitLab Pages'],
		website: 'https://saincogt.gitlab.io/react-svg-plot/',
		git: 'https://github.com/saincogt/react-svg-plot',
	},
	{
		id: 16,
		title: 'FeedMBack',
		intro: 'Send survey emails and collect feedback from the users',
		description:
			"FeedMBack is a web application built with MERN stack(MongoDB, Express, React, Node). Mailer is implemented using SendGrid. Payment is handled by Stripe. The application is hosted on Heroku. The user can login with a Google account, customise a survey email and bulk send the survey to his/her customers. Once a recipient reacted to the survey, the response will be recorded and will reflect on the user's dashboard page. User can buy additional credits for sending more surveys.",
		image: pic16,
		color: '#546e7a',
		tags: [
			'MERN full stach',
			'React',
			'Node',
			'Redux',
			'Express',
			'MongoDB',
			'Material UI',
			'Ant Design UI',
			'Styled-Components',
			'Axios',
			'Lodash',
			'Google OAuth',
			'Passport',
			'SendGrid',
			'Stripe',
			'Heroku',
		],
		website: 'https://feedmback.herokuapp.com/',
		git: 'https://github.com/saincogt/feedmback',
	},
];

export const portfolioIntro = {
	name: 'Portfolio',
	intro:
		'I have been involved in the full implementation of many projects, including design, development, and testing as a Web Programmer. I am always keen to create something interesting in my spare time to practice my skills and learn new knowledges. These are some of my previous projects and web apps I recently created. There are a few more projects I had been working on and I am happy to introduce and explain if you would like to learn more.',
};

export const sections = [
	{ id: 'top', name: 'Intro', icon: 'fa-home' },
	{ id: 'portfolio', name: 'Portfolio', icon: 'fa-th' },
	{ id: 'about', name: 'About Me', icon: 'fa-user' },
	{ id: 'contact', name: 'Contact', icon: 'fa-envelope' },
];
