import React from 'react';
import { motion } from 'framer-motion';

import LinkIcon from '../assets/images/link-icon.svg';
import GitIcon from '../assets/images/git-icon.png';

const Modal = ({
	setModal,
	smallScreen,
	modal: { image, title, website, git, tags, description },
}) => {
	const handleClick = e => {
		if (e.target.classList.contains('backdrop')) setModal(null);
	};
	return (
		<motion.div
			className='backdrop'
			onClick={handleClick}
			style={{ zIndex: 2 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
		>
			<motion.div
				className={
					smallScreen ? 'modal-content-small' : 'modal-content'
				}
				initial={{ y: '-100vh' }}
				animate={{ y: 0 }}
			>
				<div className='modal-grid-container'>
					<div className='modal-grid-item-left'>
						<div className='modal-first-line-container'>
							<span className='modal-title'>{title}</span>
							<div className='modal-icon-container'>
								{website && (
									<a
										href={website}
										className='modal-link'
										target='_blank'
										rel='noopener noreferrer'
									>
										<img
											src={LinkIcon}
											alt='LinkIcon'
											className='modal-custom-icon'
										/>
									</a>
								)}
								{git && (
									<a
										href={git}
										className='modal-link'
										target='_blank'
										rel='noopener noreferrer'
									>
										<img
											src={GitIcon}
											alt='GitIcon'
											className='modal-custom-icon'
										/>
									</a>
								)}
							</div>
						</div>
						<p className='modal-text'>{description}</p>
						<div className='modal-tag-container'>
							<p className='modal-tag-text'>Keywords:</p>
							{tags.map((tag, idx) => (
								<span className='modal-tag' key={idx}>
									{tag}
								</span>
							))}
						</div>
					</div>

					<div className='modal-grid-item-right'>
						<img
							src={image}
							alt={title}
							className='modal-image'
						></img>
					</div>
				</div>
			</motion.div>
		</motion.div>
	);
};

export default Modal;
