import React, { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import SideBar from '../components/Sidebar/index';
import Contact from '../components/Contact';
import { projects, sections, portfolioIntro } from '../data/data';

import aboutMe from '../assets/images/bg-me.jpg';

import Scroll from '../components/Scroll';
import ProjectContainer from '../components/ProjectContainer';
import Modal from '../components/Modal';

const IndexPage = () => {
	const [modal, setModal] = useState(null);
	const [smallScreen, setSmallScreen] = useState(false);

	const resize = () => {
		setSmallScreen(window.innerWidth <= 840);
	};

	useEffect(() => {
		window.addEventListener('resize', () => resize());
	}, []);
	return (
		<Layout>
			<SideBar sections={sections} />
			<div id='main'>
				<section id='top' className='one dark cover'>
					<div className='container'>
						<header>
							<h2 className='alt'>
								Hello! I am <strong>Sancho Saiyin</strong>
								<br /> I am a Web developer
							</h2>
							<p>I have made a few things check it out.</p>
						</header>
						<footer>
							<Scroll type='id' element={'portfolio'}>
								<a className='learn-more' href='#portfolio'>
									<span>Show Me</span>
									<i className='icon fa-chevron-down right-icon'></i>
								</a>
							</Scroll>
						</footer>
					</div>
				</section>

				<section id='portfolio' className='two'>
					<div className='container'>
						<header>
							<h2>{portfolioIntro.name}</h2>
						</header>
						<p className='text-body'>{portfolioIntro.intro}</p>
					</div>
					<ProjectContainer projects={projects} setModal={setModal} />
				</section>

				<section id='about' className='three'>
					<div className='container'>
						<img src={aboutMe} alt='' className='about-me' />
						<header>
							<h2>About Me</h2>
						</header>
						<p className='text-body'>
							Hi there, My name is Sancho Saiyin, I am a Web
							Developer. I have been working in the Web
							Development field for 3+ years after receiving my
							Master Degree of Software Engineering from Charles
							Darwin University. Before that I also got 6 years'
							professional work experience in the Software
							Development industry as a Software Engineer. I enjoy
							coding from scratch, combining design and business
							logic to beautiful web applications. I am looking
							forward to working with you.
						</p>
					</div>
				</section>

				<section id='contact' className='four'>
					<Contact />
				</section>
				{modal && (
					<Modal
						modal={modal}
						setModal={setModal}
						smallScreen={smallScreen}
					/>
				)}
			</div>
		</Layout>
	);
};

export default IndexPage;
